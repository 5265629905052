import React from 'react';
import Fade from 'react-reveal/Fade';

import Card from 'react-bootstrap/Card'

import '../css/ServicesContainer.css'

function ServicesContainer() {

  return (
    <container className='secondary-container'>

      <div className='services' id='services'>
        <p className='contTitle'>Услуги</p>
        <Fade left>
          <Card className='serviceCard leftCard'>
            <Card.Body>
              <Card.Title>SMM МАРКЕТОЛОГ «ПОД КЛЮЧ» 50&nbsp;000₽&nbsp;/&nbsp;месяц</Card.Title>
              <Card.Text>
                <ul class="text-left card-list">
                  <li>СОЗДАНИЕ И НАСТРОЙКА АККАУНТА INSTAGRAM</li>
                  <li>РАЗРАБОТКА СТРАТЕГИИ ПРОДВИЖЕНИЯ ВАШЕГО БРЕНДА В INSTAGRAM</li>
                  <li>РАЗРАБОТКА ФИРМЕННОГО СТИЛЯ</li>
                  <li>РАЗРАБОТКА ВИЗУАЛЬНОЙ КОНЦЕПЦИИ (посты + сторис + актуальные сторис)</li>
                  <li>КОПИРАЙТИНГ (написание постов)</li>
                  <li>ПОСТИНГ (в пакет услуг входит 15-20 постов в месяц и 4-6 stories в день)</li>
                  <li>СОСТАВЛЕНИЕ ПЛАНА ПО СЮЖЕТАМ ДЛЯ СТОРИС</li>
                  <li>СТИЛИЗАЦИЯ И ОРГАНИЗАЦИЯ ФОТОСЕССИИ (в любом городе)</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Fade>

        <Fade right>
          <Card className='serviceCard rightCard'>
            <Card.Body>
              <Card.Title>ТАРГЕТИРОВАННАЯ РЕКЛАМА от 20&nbsp;000₽</Card.Title>
              <Card.Text>
                <ul class="text-left card-list">
                  <li>ПРОДВИЖЕНИЕ В СОЦИАЛЬНОЙ СЕТИ ВКОНТАКТЕ</li>
                  <li>РАЗРАБОТКА КРЕАТИВОВ И РЕКЛАМНЫХ ОБЪЯВЛЕНИЙ</li>
                  <li>НАСТРОЙКА РЕКЛАМНЫХ КАМПАНИЙ</li>
                  <li>БЮДЖЕТ НА ТАРГЕТИРОВАННУЮ РЕКЛАМУ ЗАКЛАДЫВАЕТСЯ ОТДЕЛЬНО, ИСХОДЯ ИЗ ВАШИХ ЦЕЛЕЙ И ВОЗМОЖНОСТЕЙ</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Fade>

        <Fade left>
          <Card className='serviceCard leftCard'>
            <Card.Body>
              <Card.Title>КОНСУЛЬТАЦИЯ ДЛЯ БИЗНЕСМЕНОВ И SMM-СПЕЦИАЛИСТОВ от 10&nbsp;000₽</Card.Title>
              <Card.Text>
                <ul class="text-left card-list">
                  <li>РАЗБОР ВСЕХ ВАШИХ ВОПРОСОВ</li>
                  <li>ДИАГНОСТИКА АККАУНТА И РАБОТЫ МЕНЕДЖЕРА ПО ПРОДАЖАМ В ДИРЕКТЕ</li>
                  <li>ОБСУЖДЕНИЕ ТОНКОСТЕЙ ПРОДАЖ В ПЕРЕПИСКЕ С ПОТЕНЦИАЛЬНЫМ КЛИЕНТОМ</li>
                  <li>ПОИСК СИЛЬНЫХ И СЛАБЫХ СТОРОН, ПЛАН ПО РАБОТЕ НАД НИМИ</li>
                  <li>ПОИСК НОВЫХ ТОЧЕК РОСТА БИЗНЕСА</li>
                  <li>АНАЛИЗ РЕКЛАМНЫХ КАМПАНИЙ</li>
                  <li>ПОЛУЧИТЕ ПЛАН ДЕЙСТВИЙ, ЧТО ДОБАВИТЬ В КОНТЕНТ, ЧТОБЫ БЫЛИ ПРОДАЖИ ЧЕРЕЗ СОЦСЕТИ</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Fade>

      </div>

    </container>
  );
}

export default ServicesContainer;
