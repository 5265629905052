import React from 'react';
import {useState} from 'react';

import '../css/MyNavbar.css';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import { FaInstagram, FaWhatsapp, FaEnvelope } from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function MyNavbar() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expanded={expanded} bg="light" expand="lg" fixed='top'>
      <Navbar.Brand href="#intro" onClick={() => setExpanded(false)}>25/8 AGENCY</Navbar.Brand>
      <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")}/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="#services" onClick={() => setExpanded(false)}>Услуги</Nav.Link>
          <Nav.Link href="#cases" onClick={() => setExpanded(false)}>Кейсы</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link href="https://www.instagram.com/25.8agency/"><FaInstagram/></Nav.Link>
          <Nav.Link href="https://wa.me/79272888838"><FaWhatsapp/></Nav.Link>
          <Nav.Link href="mailto:25agency8@gmail.com"><FaEnvelope/></Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}//data-toggle="collapse" data-target="#navbarSupportedContent"

export default MyNavbar;
