import React from 'react';

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import MyNavbar from './components/MyNavbar.js'
import IntroContainer from './components/IntroContainer.js'
import ServicesContainer from './components/ServicesContainer.js'
import CasesContainer from './components/CasesContainer.js'
import BrifContainer from './components/BrifContainer.js'
import MyFooter from './components/MyFooter.js'

import {Helmet} from "react-helmet";

function App() {

  return (
    <div className="App">
      <MyNavbar/>

      <header className="App-header">
        <IntroContainer/>
      </header>

      <ServicesContainer/>
      <CasesContainer/>
      <MyFooter/>

      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
        crossorigin="anonymous"
      />
      <script src="./js/scroll.js"></script>
    </div>
  );
}

export default App;
