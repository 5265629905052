import React from 'react';
import Fade from 'react-reveal/Fade';

import '../css/Logo.css'

function Logo() {

  return (
    <div className="logo">

      <div className="top">

        <div className='upPart'>

          <div className="upLogo"/>
          <img src='logo1.png' alt='Комплексный SMM. Управление репутацией. Создание контента.'/>

        </div>

        <div className="midPart">
          <img src='logo2.png' alt='Комплексный SMM. Управление репутацией. Создание контента.'/>
        </div>

      </div>

      <div className='bottomPart'>

        <div className="bottom">
          <Fade delay={1300}>
            <img src='logo3.png' alt='Комплексный SMM. Управление репутацией. Создание контента.'/>
          </Fade>
        </div>

      </div>

    </div>
  );
}

export default Logo;
