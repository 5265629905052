import React from 'react';
import Fade from 'react-reveal/Fade';

import { Controller,Scene } from 'react-scrollmagic';

import { FaInstagram } from "react-icons/fa";

function CaseScene(props){
  return (
    <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
      <Scene duration={props.duration} pin>
        <div className="panel">
          <div className='case'>
            <img src={props.name + '1.PNG'}  alt={props.alt}/>
            <Fade>
              <img src={props.name + '2.PNG'} alt={props.alt}/>
            </Fade>
            <Fade bottom>
              <a href={props.src}><FaInstagram/></a>
            </Fade>
          </div>
        </div>
      </Scene>
    </Controller>
  )
}


export default CaseScene;
