import React from 'react';
import Fade from 'react-reveal/Fade';

import Logo from './Logo.js'
import Button from 'react-bootstrap/Button'

import '../css/IntroContainer.css'

function IntroContainer() {

  return (
    <container className='center-content' id='intro'>
      <Logo/>

      <div className='introText'>
        <Fade left delay={1400}>
          <p>Онлайн&#8209;маркетинг для вашего бизнеса.</p>
        </Fade>
        <Fade left delay={1500}>
          <p>Увеличим продажи в вашем бизнесе при эффективной совместной работе</p>
        </Fade>
        <Fade left delay={1500}>
          <Button href='#services' variant='outline-light' size='lg'>
            Пакеты услуг
          </Button>
        </Fade>

      </div>
    </container>
  );
}

export default IntroContainer;
