import React from 'react';
import '../css/CasesContainer.css'
import CaseScene from './CaseScene.js';

function CasesContainer() {

  return (
    <container>

      <div className='cases' id='cases'>
        <p className='contTitle'>Кейсы</p>
        <hr/>
        <CaseScene name='expertclinic' duration='0' src='https://instagram.com/expertclinic62' alt='ExpertClinic'/>
        <CaseScene name='AprioriMarried' duration='0' src='https://instagram.com/apriorimarried?igshid=YmMyMTA2M2Y=' alt='AprioriMarried'/>
        <CaseScene name='KIDDO' duration='0' src='https://instagram.com/kiddo_kindergarten?igshid=YmMyMTA2M2Y=' alt='KIDDO'/>
        <CaseScene name='budi' duration='0' src='https://instagram.com/budi_vilnius' alt='BUDI'/>
        <CaseScene name='kaknado' duration='0' src='https://instagram.com/kaknado.penza?igshid=YmMyMTA2M2Y=' alt='Как надо'/>
        <CaseScene name='spikaknado' duration='0' src='https://instagram.com/spi_kak_nado' alt='Спи как надо'/>
        <CaseScene name='lavontenok' duration='1' src='https://instagram.com/lavontenok?igshid=YmMyMTA2M2Y=' alt='Lavontenok'/>
      </div>

    </container>
  );
}

export default CasesContainer;
