import React from 'react';
import '../css/Footer.css'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FaInstagram, FaWhatsapp, FaEnvelope } from "react-icons/fa";

function MyFooter() {

  return (
    <footer>
      <Row className="justify-content-md-center">
        <Col>
          <a href="https://www.instagram.com/25.8agency/"><FaInstagram/></a>
          <a href="https://wa.me/79272888838"><FaWhatsapp/></a>
          <a href="mailto:25agency8@gmail.com"><FaEnvelope/></a>
        </Col>
      </Row>
      <Row>
        <Col>
          <a href='tel:+79272888838'>+7 (927) 288-88-38</a>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col>
          <a href='#services'>Услуги</a>
          <a href='#cases'>Кейсы</a>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className='copyright'>25/8 AGENCY © {new Date().getFullYear()}</p>
        </Col>
      </Row>
    </footer>
  );
}

export default MyFooter;
