import React from 'react';
import '../css/BrifContainer.css'
import Button from 'react-bootstrap/Button'

function BrifContainer() {

  return (
    <container className='secondary-container brif-container center-content' id='brif'>

      <div className='brif'>
        <p className='contTitle'>Бриф</p>
        <hr/>
        <p>БЛАГОДАРИМ ЗА ПРОЯВЛЕННЫЙ ИНТЕРЕС!</p> <br/>
        <p>ВЫ МОЖЕТЕ ЗАПОЛНИТЬ ЭТОТ БРИФ ДЛЯ ТОГО, ЧТОБЫ МЫ СМОГЛИ ПОДГОТОВИТЬ ДЛЯ ВАС УНИКАЛЬНОЕ ПРЕДЛОЖЕНИЕ О СОТРУДНИЧЕСТВЕ.</p><br/>
        <p>МЫ СВЯЖЕМСЯ С ВАМИ ПОСЛЕ ЗАПОЛНЕНИЯ.</p>

        <Button href='https://docs.google.com/forms/d/e/1FAIpQLSdKE58cZkY9OHnGCTn3Cyb751L7sbaH_WPDUPRxga-SWkqITA/viewform' variant='outline-dark' size='lg'>
          Заполнить бриф
        </Button>
      </div>

    </container>
  );
}

export default BrifContainer;
